import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { Button } from '~/components/ui/button';
import Image from '~/components/ui/image';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs';
import { FIELDCONST } from '~/const';
import { FIELD } from '~/types/field';
import Background from './background';
import Card from './card';
import Contact from './contact';

const OurFields = ({ isRegisterClosed }: { isRegisterClosed: boolean }) => {
  const [data, setData] = useState<FIELD>(FIELDCONST[0]);
  const { name, description, criteria, hrdContact } = data;

  const navigate = useNavigate();

  return (
    <div className="relative px-20 max-md:px-14 max-sm:px-7 flex flex-col gap-5 overflow-x-clip mt-12 lg:mt-24">
      <Background />
      <h1
        id="fields"
        className="text-6xl max-lg:text-5xl max-md:text-4xl max-sm:text-3xl text-center font-tilt-warp bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text w-fit mx-auto"
      >
        Our Fields
      </h1>
      <div className="hidden max-lg:flex z-[1]">
        <Select
          onValueChange={(value) =>
            setData(
              FIELDCONST.find((item: FIELD) => item.name === value) ||
                FIELDCONST[0]
            )
          }
        >
          <SelectTrigger className="py-6">
            <SelectValue placeholder="Choose Field" />
          </SelectTrigger>
          <SelectContent>
            {FIELDCONST.map((item, index) => {
              return (
                <SelectItem key={index} value={item.name}>
                  {item.name}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
      <div className="z-[1] hidden max-lg:flex flex-col gap-5">
        <Tabs
          className="flex flex-col justify-center gap-5"
          defaultValue="about"
        >
          <TabsList>
            <TabsTrigger value="about" className="px-0 w-full">
              About
            </TabsTrigger>
            <TabsTrigger value="job-description" className="px-0 w-full">
              Criteria
            </TabsTrigger>
            <TabsTrigger value="contact" className="px-0 w-full">
              Contact
            </TabsTrigger>
          </TabsList>
          <TabsContent value="about">
            <Card title={'Description'} description={[description]} />
          </TabsContent>
          <TabsContent value="job-description">
            <Card title={'Criteria'} description={[criteria]} />
          </TabsContent>
          <TabsContent value="contact">
            <Contact
              title={'HR Contact Person'}
              name={hrdContact.name}
              whatsapp={hrdContact.whatsapp}
              line={hrdContact.line}
            />
          </TabsContent>
        </Tabs>
        <Button
          variant={'primary'}
          className="w-full h-12"
          onClick={() => {
            navigate('/register');
          }}
          disabled={isRegisterClosed}
        >
          <Image
            src="./li_user-circle.svg"
            alt="User icon"
            className="w-6 h-6"
          />
          Register
        </Button>
      </div>
      <div className="z-[1] w-full max-lg:hidden grid grid-cols-[minmax(0,208px)_minmax(0,1fr)] max-lg:grid-cols-1 gap-5">
        <div className="flex flex-col h-auto gap-5 justify-between max-lg:hidden">
          {FIELDCONST.map((item, index) => {
            return (
              <button
                key={index}
                className={`w-52 text-base duration-300 transition-all hover:opacity-80 flex items-center gap-2 ${name === item.name ? 'bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 text-white' : 'bg-neutral-050 dark:bg-[#151515]'} border border-neutral-100 dark:border-neutral-900 rounded-[12px] py-3 px-5 font-ubuntu font-bold`}
                onClick={() => setData(item)}
              >
                <Image
                  src={`/brands/fields/${item.code}.webp`}
                  alt={`${item.name} icon`}
                  className="w-6 h-6 invert dark:invert-0"
                  imageClassName='object-contain'
                />
                {item.name}
              </button>
            );
          })}
        </div>
        <div className="w-full h-full flex flex-col gap-5 justify-between">
          <div className="w-full h-full grid grid-cols-[minmax(0,1fr)_minmax(0,0.5fr)] max-lg:grid-cols-[minmax(0,0.9fr)_minmax(0,0.6fr)] gap-5">
            <div className="w-full h-full flex flex-col gap-5">
              <Card title={'Description'} description={[description]} />
              <Card title={'Criteria'} description={[criteria]} />
            </div>
            <Contact
              title={'HR Contact Person'}
              name={hrdContact.name}
              whatsapp={hrdContact.whatsapp}
              line={hrdContact.line}
            />
          </div>
          <Button
            variant={'primary'}
            className="w-full h-12"
            onClick={() => {
              navigate('/register');
            }}
            disabled={isRegisterClosed}
          >
            <Image
              src="./li_user-circle.svg"
              alt="User icon"
              className="w-6 h-6"
            />
            Register
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OurFields;
