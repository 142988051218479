import Testimony from '~/components/elements/Testimonial/Testimony';
import { TESTIMONIES } from '../constants';

const Testimonial = () => {
  return (
    <div className="flex flex-col mt-24 lg:mt-40">
      <h1 className="text-6xl max-lg:text-5xl max-md:text-4xl max-sm:text-3xl text-center font-tilt-warp">
        What They Said About{' '}
        <span className="font-tilt-warp bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
          Compfest
        </span>
      </h1>
      <Testimony slides={TESTIMONIES} />
    </div>
  );
};

export default Testimonial;
