import { ClientLoaderFunctionArgs } from '@remix-run/react';

export default async function LandingClientLoader({
  serverLoader,
}: ClientLoaderFunctionArgs) {
  const serverData = (await serverLoader()) as {
    isRegistered: boolean;
    isSubmitted: boolean;
  };

  return {
    ...serverData,
  };
}
