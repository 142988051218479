import type { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Image from '~/components/ui/image';
import type { TestimonyProps } from './interface';
import { CarouselButton } from './TestimonyButton';

type PropType = {
  slides: TestimonyProps[];
  options?: EmblaOptionsType;
};

const Testimony: React.FC<PropType> = ({ slides, options }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    ...options,
    loop: true,
    slidesToScroll: 1,
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setActiveIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    return () => {
      emblaApi.off('select', onSelect);
    };
  }, [emblaApi, onSelect]);

  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-x-hidden overflow-hidden pt-32 pb-10">
      <div className="relative">
        <div className="" ref={emblaRef}>
          <div className="flex -mx-4">
            {slides.map((slide, index) => (
              <div
                className="min-w-0 px-4 flex-[0_0_80%] sm:flex-[0_0_60%] lg:flex-[0_0_33.333%]"
                key={index}
              >
                <div
                  className={`h-80 bg-component-light-card dark:bg-component-dark-card border-[1px] border-component-light-border dark:border-component-dark-border rounded-2xl shadow-lg transition-all duration-300 ease-in-out transform ${
                    index === activeIndex && slides.length > 3
                      ? 'scale-100 opacity-100'
                      : 'scale-95 opacity-35'
                  }`}
                >
                  <div className="p-10 space-y-4 relative">
                    <Image
                      alt="profile"
                      src={
                        slide.profilePic ?? '/brands/profile-placeholder.png'
                      }
                      errorImage="/brands/profile-placeholder.png"
                      className="w-40 aspect-square mx-auto bg-neutral-100 dark:bg-neutral-900 rounded-full absolute -top-20 left-1/2 -translate-x-1/2"
                      imageClassName="w-full h-full object-cover rounded-full"
                    />
                    <div className="pt-10 font-ubuntu space-y-2">
                      <div>
                        <h3 className="text-xl font-bold text-center text-gray-900 dark:text-white">
                          {slide.name}
                        </h3>
                        <h4 className="text-sm text-center">
                          {slide.position}
                        </h4>
                      </div>
                      <p className="text-xs md:text-sm text-black dark:text-white  text-center md:text-justify overflow-auto max-h-24">
                        {slide.testimony}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {slides.length > 3 && (
          <div className="absolute top-1/2 left-0 right-0 flex justify-between transform -translate-y-1/2">
            <CarouselButton
              direction="prev"
              onClick={scrollPrev}
              className="-ml-3 md:ml-20 lg:ml-52 xl:ml-80"
            />
            <CarouselButton
              direction="next"
              onClick={scrollNext}
              className="-mr-3 md:mr-20 lg:mr-52 xl:mr-80"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimony;
