import { TestimonyProps } from '~/components/elements/Testimonial/interface';
import { TimelineEvent } from '~/components/elements/Timeline/interface';

export const TIMELINE_EVENTS: TimelineEvent[] = [
  {
    eventName: 'HRD Staff Open Recruitment Begins',
    eventDate: '3 Maret 2025',
  },
  {
    eventName: 'Close Recruitment for HRD Staff',
    eventDate: '9 March 2025',
  },
  {
    eventName: 'HRD Staff Announcement',
    eventDate: '11 March 2025',
  },
  {
    eventName: 'Open Recruitment for Non-HRD Staff Begins',
    eventDate: '13 March 2025',
  },
  {
    eventName: 'Close Recruitment for Non-HRD Staff',
    eventDate: '19 March 2025',
  },
  {
    eventName: 'Open Recruitment Batch 2 for Non-HRD Staff Begins',
    eventDate: '26 March 2025',
  },
  {
    eventName: 'Close Recruitment for Non-HRD Staff Batch 2',
    eventDate: '2 April 2025',
  },
  {
    eventName: 'COMPFEST 17 Staff Announcement',
    eventDate: '6 April 2025',
  },
];

export const ACHIEVEMENTS = [
  {
    title: 350,
    description: 'Dedicated staff driving success together every year',
  },
  {
    title: 70000,
    description: 'Annual website visits, attracting widespread attention',
  },
  {
    title: 800000,
    description: 'Total website visits since inception',
  },
  {
    title: 30000,
    description: 'Active social media followers, building a strong community',
  },
  {
    title: 430,
    description: 'Exhibitors showcasing innovation and top industry solutions',
  },
  {
    title: 13000,
    description: 'Registered users on the website',
  },
  {
    title: 15000,
    description: 'Potential market opportunities',
  },
  {
    title: 140,
    description: 'Sponsors supporting continuous growth',
  },
  {
    title: 700,
    description: 'Media partners expanding reach',
  },
  {
    title: 60,
    description: 'Countries involved on a global scale',
  },
];

export const TESTIMONIES: TestimonyProps[] = [
  {
    name: 'Leonita Cecilia',
    position: 'Product Management Academy',
    testimony:
      'Seru banget bisa menjadi bagian dari Academy COMPFEST! Di Academy, kita bukan hanya sekadar mengerjakan tugas yang diberikan, tapi juga bisa belajar dari para expert dan membangun networking yang keren. See you on COMPFEST 17!',
    profilePic: '/brands/testimonial/IMG_3700 - Leonita Cecilia.jpeg',
  },
  {
    name: 'Adrin',
    position: 'Stage',
    testimony:
      'Sebelum join compfest beneran hampir gatau apa-apa soal compfest dan setelah join ke compfest it really amazed me kalau compfest itu adalah acara IT kampus terbesar se-Indonesia salah satu keputusan terbaik buat join compfest 🤩🤩',
    profilePic: '/brands/testimonial/IMG_2481 - Sulthan Siregar.jpeg',
  },
  {
    name: 'Ridya Azizah',
    position: 'Playground',
    testimony:
      'Pengalamanku selama kemarin jadi staf event khususnya di divisi playgroundd itu seruu banget!! walaupun itu pertama kali aku jadi staf event, tapi aku enjooy di sinii. aku bisa belajar gimana caranya nge approach perusahaan-perusahaan IT untuk jadi exhibitor di playground main event, dan belajar juga caranya untuk berkomunikasi dengan pihak eksternal. nah apalagii pas di main event, dari event ituu ada booth perusahaan exhibitor playground, compfest talks, supermarket, game zone, dan masih banyak lagii pokoknya seru banget!! nyesel sih kalo gaikut event jadi jangan lupa daftaarr eventt‼️',
    profilePic:
      '/brands/testimonial/Screenshot_20250301_171542_Gallery - Ridya Azizah.jpg',
  },
  {
    name: 'Micheline Wijaya Limbergh',
    position: 'Business Development',
    testimony:
      'Berkembang bersama di Divisi Business Development adalah pengalaman yang seru banget! Dibimbing oleh kakak-kakak Finance, saya banyak belajar hal baru tentang IT Project Management melalui berbagai workshop yang insightful. Setelah itu, langsung mengeksekusi proyek dengan klien menjadi tantangan sekaligus pengalaman yang tak terlupakan. Selain itu, bonding dan kerja sama dengan tim Merchandise di setiap meeting yang compact juga sangat menyenangkan! 🌸 *Just like a flower waiting to flourish. Are you ready to flourish with us?* 🌸',
    profilePic:
      '/brands/testimonial/MichelineWijayaLimbergh_FotoDiri - Micheline Wijaya L.jpg',
  },
  {
    name: 'Indah Cahya Puspitahati',
    position: 'Media Partner',
    testimony:
      'jadi bagian dari partnership CF16 especially divisi media partner tuh bener-bener pengalaman yang seru dan insightful banget‼️🙌🏻 dari awal sampe akhir aku belajar banyak bgt tentang approaching dan tektokan langsung sama pihak external, mulai dari ngajak kerja sama, negosiasi, sampe pastiin publikasi CF itu tayang di platform mereka. serunya aku jadi bisa belajar gimana sih cara komunikasi yang efektif dan pastinya bangun relasi yang baik sama pihak luar, pokoknya bener-bener INSIGHTFUL BGT DEHHH!!🤩🔥',
    profilePic: '/brands/testimonial/IMG_2643 2 - Indah Cahya Puspitahati.jpeg',
  },
];
