import { Link } from '@remix-run/react';
import { Button } from '~/components/ui/button';
import Image from '~/components/ui/image';

const propsTakeATest = {
  title: 'Take A Test',
  question: 'Still not sure what to choose?',
  description: 'Take a test to find out which division suits you best!',
  link: '/take-a-test',
};

const TakeATest = () => {
  const { title, question, description, link } = propsTakeATest;

  return (
    <div className="w-full px-20 max-md:px-14 max-sm:px-7 mt-24 lg:mt-40">
      <div className="w-full h-full p-14 max-sm:p-6 bg-neutral-050 dark:bg-[#151515] border border-neutral-100 dark:border-neutral-900 rounded-[28px] max-sm:rounded-[16px] flex gap-12 max-lg:flex-col max-sm:gap-6">
        <div className="relative w-[310px] max-md:w-[200px] max-sm:w-[141px] aspect-[1240/1134] self-center">
          <Image
            src="./takeatestmascot.png"
            alt="take-a-test"
            className="object-contain duration-300 hover:rotate-12"
          />
        </div>
        <div className="flex flex-col gap-3 justify-between">
          <h1 className="w-max max-lg:w-full max-lg:text-center text-6xl max-lg:text-5xl max-md:text-4xl max-sm:text-3xl text-center font-tilt-warp bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
            {title}
          </h1>
          <div className="font-ubuntu max-lg:text-center text-black dark:text-white text-2xl max-lg:text-xl max-md:text-lg max-sm:text-base font-semibold flex flex-col gap-2">
            <p>{question}</p>
            <p>{description}</p>
          </div>
          <Link className="self-center lg:self-start" to={link}>
            <Button className="">
              <Image
                src="./li_file-text.svg"
                alt="take-a-test"
                className="object-contain"
              />
              Take a Test
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TakeATest;
