import React from 'react';
import Image from '~/components/ui/image';

interface CardProps {
  title: string;
  src: string;
  alt: string;
}

const Card: React.FC<CardProps> = ({ title, src, alt }) => (
  <div className="bg-white dark:bg-[#151515] w-full max-md:min-w-40 p-2 sm:p-2.5 md:p-3 lg:p-6 rounded-xl md:rounded-2xl lg:rounded-3xl group hover:scale-105 transition-transform duration-300">
    <h1 className="text-xs sm:text-sm md:text-base lg:text-xl xl:text-3xl font-tilt-warp">
      {title}
    </h1>
    <Image
      src={src}
      className="mt-2 lg:mt-6 max-md:p-1 group-hover:rotate-12 duration-300 hover:scale-110"
      alt={alt}
    />
  </div>
);

export default function CoreValues(): JSX.Element {
  const coreValues: CardProps[] = [
    {
      title: 'Impactful',
      src: './core-values/impactful.png',
      alt: 'Illustration for Impactful',
    },
    {
      title: 'Collaborative',
      src: './core-values/collaborative.png',
      alt: 'Illustration for Collaborative',
    },
    {
      title: 'Adaptive',
      src: './core-values/adaptive.png',
      alt: 'Illustration for Adaptive',
    },
  ];

  const workCulture: CardProps[] = [
    {
      title: 'Dedication',
      src: './core-values/dedication.png',
      alt: 'Illustration for Dedication',
    },
    {
      title: 'Belonging',
      src: './core-values/belonging.png',
      alt: 'Illustration for Belonging',
    },
    {
      title: 'Synergy',
      src: './core-values/synergy.png',
      alt: 'Illustration for Synergy',
    },
  ];

  return (
    <div className="relative w-full h-full mt-16 lg:mt-48 mb-12 px-24 max-md:px-16 max-sm:px-10">
      <div className="absolute z-40 top-0 left-1/2 -translate-x-1/2 w-full px-20 max-md:px-0 max-md:pl-14 max-sm:pl-7">
        <h1 className="text-center text-2xl md:text-3xl lg:text-5xl xl:text-6xl font-tilt-warp">
          Our Core Values
        </h1>
        <div className="flex md:justify-center gap-3 md:gap-5 lg:gap-8 mt-5 lg:mt-10 max-md:overflow-auto max-md:[&::-webkit-scrollbar]:hidden max-md:[-ms-overflow-style:none] max-md:[scrollbar-width:none]">
          {coreValues.map((value, index) => (
            <Card
              key={index}
              title={value.title}
              src={value.src}
              alt={value.alt}
            />
          ))}
        </div>
        <h1 className="text-center text-2xl md:text-3xl lg:text-5xl xl:text-6xl font-tilt-warp mt-12 md:mt-6 lg:mt-8 xl:mt-28">
          Our Work Culture
        </h1>
        <div className="flex md:justify-center gap-3 md:gap-5 lg:gap-8 mt-5 lg:mt-10 max-md:overflow-auto max-md:[&::-webkit-scrollbar]:hidden max-md:[-ms-overflow-style:none] max-md:[scrollbar-width:none]">
          {workCulture.map((value, index) => (
            <Card
              key={index}
              title={value.title}
              src={value.src}
              alt={value.alt}
            />
          ))}
        </div>
      </div>
      <Image
        src="./landing-core-values-light-desktop.png"
        alt=""
        className="w-full dark:hidden max-md:hidden"
        imageClassName="w-full"
      />
      <Image
        src="./landing-core-values-dark-desktop.png"
        alt=""
        className="w-full hidden dark:md:inline"
        imageClassName="w-full"
      />
      <Image
        src="./landing-core-values-light-mobile.png"
        alt=""
        className="w-full dark:hidden md:hidden"
        imageClassName="w-full"
      />
      <Image
        src="./landing-core-values-dark-mobile.png"
        alt=""
        className="w-full hidden max-md:dark:inline"
        imageClassName="w-full"
      />
    </div>
  );
}
