import { useEffect, useMemo, useRef } from 'react';
import Image from '~/components/ui/image';
import { ACHIEVEMENTS } from '../constants';

const OurAchievements = () => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Randomly select 4 achievements once when the component mounts
  const randomAchievements = useMemo(() => {
    const shuffled = [...ACHIEVEMENTS].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, 4);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLDivElement;
            const endValue = parseInt(target.dataset.value || '0', 10);
            let startValue = 0;
            const duration = 4000;
            const increment = endValue / (duration / 16);

            const count = () => {
              startValue += increment;
              if (startValue < endValue) {
                target.innerText = `${Math.floor(startValue)}+`;
                requestAnimationFrame(count);
              } else {
                target.innerText = `${endValue}+`;
              }
            };

            count();
            observer.unobserve(target);
          }
        });
      },
      { threshold: 0.5 }
    );

    itemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="relative w-full h-full px-20 py-32 max-lg:py-20 max-md:py-16 max-sm:py-10 max-md:px-14 max-sm:px-7 flex flex-col gap-11 max-sm:gap-5 mt-12 xl:mt-24">
      <div className="w-full z-[1] relative flex justify-center items-center">
        <div className="absolute transition-all duration-300 top-1/2 w-full h-60 max-md:h-52 max-sm:h-14 rounded-[28px] max-sm:rounded-2xl bg-neutral-50 dark:bg-[#151515] border border-neutral-100 dark:border-neutral-900 z-0"></div>
        <h1 className="relative text-6xl max-lg:text-5xl max-md:text-4xl max-sm:text-3xl text-center font-tilt-warp bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
          Our Achievements
        </h1>
      </div>

      <div className=" z-[1] w-full sm:px-12  gap-5 grid grid-cols-4 max-lg:grid-cols-2 [&>div]:rounded-[28px] [&>div]:bg-neutral-050 dark:[&>div]:bg-neutral-900 [&>div]:border [&>div]:border-neutral-100 dark:[&>div]:border-neutral-900">
        {randomAchievements.map((item, index) => {
          return (
            <div
              key={index}
              className="w-full h-auto px-10 py-9 max-md:px-6 max-md:py-6 flex flex-col gap-6 max-sm:gap-2 hover:-translate-y-6 max-lg:hover:-translate-y-2 transition-all duration-300"
            >
              <h1
                ref={(el) => (itemRefs.current[index] = el)}
                data-value={item.title}
                className="text-5xl max-sm:text-base font-tilt-warp text-transparent bg-clip-text bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500"
              >
                0+
              </h1>
              <p className="font-geologica text-base max-sm:text-xs dark:text-white max-sm:leading-5">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>

      <div className="absolute z-[0] top-0 right-0 w-[624px] max-lg:w-[500px] max-md:w-[400px] max-sm:w-[179px] aspect-[2880/2337]">
        <Image
          src="achievementbg1.png"
          alt="our-achievements"
          className="object-contain"
        />
      </div>

      <div className="absolute bottom-0  left-0 w-[624px] max-lg:w-[500px] max-md:w-[400px] max-sm:w-[179px] aspect-[2496/2338]">
        <Image
          src="achievementbg2.png"
          alt="our-achievements"
          className="object-contain"
        />
      </div>
    </div>
  );
};

export default OurAchievements;
