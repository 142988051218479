import { getAsset } from '~/lib/asset';
import { TimelineBoxProps, TimelineEvent, TimelineProps } from './interface';

// USAGE EXAMPLE

{
  /* <Timeline
        events={[
          {
            eventName: "Event Name",
            eventDate: "27 February 2025",
          },
          {
            eventName: "Event Name",
            eventDate: "27 February 2025",
          },
          {
            eventName: "Event Name",
            eventDate: "27 February 2025",
          },
          {
            eventName: "Event Name",
            eventDate: "27 February 2025",
          },
          {
            eventName: "Event Name",
            eventDate: "27 February 2025",
          },
        ]}
      /> */
}

const TimelineBox = ({ event, className = '' }: TimelineBoxProps) => {
  return (
    <div
      className={`min-w-[198px] md:min-w-[400px] flex flex-col gap-2 md:gap-4 ${className}`}
    >
      <div className="relative">
        <h1 className="absolute inset-0 flex items-center justify-center h-full text-xs md:text-base font-tilt-warp text-neutral-50 z-10 max-w-[150px] md:max-w-[200px] left-5 md:left-16 text-center">
          {event.eventName}
        </h1>
        <img
          src={getAsset('/misc/timeline-light.svg')}
          alt="vector"
          className="dark:hidden"
        />
        <img
          src={getAsset('/misc/timeline-dark.svg')}
          alt="vector"
          className="hidden dark:block"
        />
      </div>
      <div className="flex items-center justify-center relative h-8 md:h-14 mx-6 md:mx-8 font-bold text-xs md:text-base xl:text-lg text-black dark:text-white">
        <div
          className="rounded-full dark:hidden w-full h-full p-0.5"
          style={{
            background:
              'linear-gradient(to right, #2bb48b, #e56a1f) border-box',
            WebkitMask:
              'linear-gradient(blue 0 0) padding-box, linear-gradient(blue 0 0)',
          }}
        >
          <div className="bg-component-light-card rounded-full w-full h-full flex justify-center items-center">
            {event.eventDate}
          </div>
        </div>
        <div
          className="rounded-full hidden dark:inline w-full h-full p-0.5"
          style={{
            background:
              'linear-gradient(to right, #24E6AC, #E56A1F) border-box',
            WebkitMask:
              'linear-gradient(blue 0 0) padding-box, linear-gradient(blue 0 0)',
          }}
        >
          <div className="bg-component-dark-card rounded-full w-full h-full flex justify-center items-center">
            {event.eventDate}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Timeline: React.FC<TimelineProps> = ({
  events,
}: {
  events: TimelineEvent[];
}) => {
  return (
    <div className="w-[100%] flex items-center bg-component-light-card dark:bg-component-dark-card p-4 py-8 rounded-3xl overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
      {events.map((event, index) => (
        <TimelineBox
          key={index}
          event={event}
          className={index === 0 ? '' : '-ml-2 md:-ml-5'}
        />
      ))}
    </div>
  );
};
