import { Link } from '@remix-run/react';
import { Line } from '~/components/icons/Line';
import { WhatsApp } from '~/components/icons/WhatsApp';
import { Button } from '~/components/ui/button';
import Image from '~/components/ui/image';

const Contact = (props: {
  title: string;
  name: string;
  whatsapp: string;
  line: string;
}) => {
  const { title, name, whatsapp, line } = props;
  return (
    <div className="w-full h-full flex flex-col gap-5 overflow-hidden">
      <div className="w-full h-full flex flex-col justify-between gap-2 max-sm:gap-3 border bg-neutral-050 dark:bg-[#151515] border-neutral-100 dark:border-neutral-900 rounded-3xl px-10 py-9">
        <h1 className="font-tilt-warp text-center text-3xl max-lg:text-2xl max-md:text-xl max-sm:text-lg">
          {title}
        </h1>
        <div className="relative w-full self-center">
          <Image
            src="vim/vim-duduk.png"
            alt=""
            imageClassName="object-cover w-full"
            className="h-fit"
          />
        </div>
        <p className="text-center">{name}</p>
      </div>
      <div className="w-full flex gap-5">
        <Link to={`https://line.me/R/ti/p/${line}`} className="w-full">
          <Button variant={'ghost'} className="w-full group" disabled={!line}>
            <Line
              size="w-6 h-6"
              fill="fill-black dark:fill-white group-hover:fill-black"
            />
          </Button>
        </Link>
        <Link to={whatsapp} className="w-full">
          <Button
            variant={'ghost'}
            className="w-full group"
            disabled={!whatsapp}
          >
            <WhatsApp
              size="w-6 h-6"
              fill="fill-black dark:fill-white group-hover:fill-black"
            />
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default Contact;
