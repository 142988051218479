import { Link } from '@remix-run/react';
import { LayoutDashboard, Smile, UserCircle, Users2 } from 'lucide-react';
import Countdown from '~/components/elements/Countdown';
import { Button } from '~/components/ui/button';
import Image from '~/components/ui/image';

export default function Hero({
  loaderData,
}: {
  loaderData: {
    isRegisterClosed: boolean;
    isRegistered: boolean;
    isSubmitted: boolean;
    isLoggedIn: boolean;
  };
}) {
  const { isRegistered, isSubmitted, isLoggedIn, isRegisterClosed } =
    loaderData;

  return (
    <div className="relative">
      <div className="absolute left-0">
        <div className="hidden dark:inline">
          <Image
            src="./landing-hero-desktop-dark.webp"
            alt="Landing hero image for desktop in dark mode"
            className="hidden md:inline w-full"
            isPriority
          />
          <Image
            src="./landing_hero_mobile_dark.webp"
            alt="Landing hero image for mobile in dark mode"
            className="md:hidden w-full"
            isPriority
          />
        </div>
        <div className="dark:hidden">
          <Image
            src="./landing-hero-desktop-light.webp"
            alt="Landing hero image for desktop in light mode"
            className="hidden md:inline w-full"
            isPriority
          />
          <Image
            src="./landing_hero_mobile_light.webp"
            alt="Landing hero image for mobile in light mode"
            className="md:hidden w-full"
            isPriority
          />
        </div>
      </div>
      <div className="z-10 relative w-full flex flex-col gap-5 md:gap-10 items-center justify-center mx-auto px-5 pt-16">
        <div className="p-[1px] bg-gradient-to-r from-[#24E6AC] to-[#E56A1F] rounded-3xl">
          <div className="bg-white dark:bg-[#151515] px-4 py-2 md:px-6 md:py-3 rounded-3xl">
            <h1 className="font-ubuntu text-sm md:text-xl text-center font-bold">
              Open Recruitment Staff Batch 2
            </h1>
          </div>
        </div>
        <h1 className="font-tilt-warp text-2xl md:text-5xl font-semibold max-w-72 md:max-w-xl text-center py-2">
          We are waiting for YOU to join us!
        </h1>
        <Countdown
          targetDate={new Date('2025-04-02T23:59:00')}
          displayDate={true}
        />
        <div className="max-w-80 md:max-w-sm w-full flex flex-col gap-3">
          {!isLoggedIn ? (
            <form method="POST" action="/api/login">
              <Button
                variant="primary"
                type="submit"
                disabled={isRegisterClosed}
              >
                <UserCircle />
                Register
              </Button>
            </form>
          ) : (
            <Link
              className={isRegisterClosed ? 'pointer-events-none' : ''}
              to={
                isRegistered && isSubmitted
                  ? '/dashboard'
                  : isRegistered
                    ? '/choose/field'
                    : '/register'
              }
            >
              <Button
                variant={'primary'}
                className="w-full"
                disabled={isRegisterClosed}
              >
                {!isRegistered && !isSubmitted && (
                  <>
                    <UserCircle />
                    Register
                  </>
                )}
                {isRegistered && !isSubmitted && (
                  <>
                    <Smile />
                    Be Part of The Team Now
                  </>
                )}
                {isRegistered && isSubmitted && (
                  <>
                    <Smile />
                    See My Dashboard
                  </>
                )}
              </Button>
            </Link>
          )}
          {!isRegistered && !isSubmitted && (
            <Button
              variant={'secondary'}
              className="w-full"
              onClick={() => {
                const element = document.getElementById('fields');
                const offset = -100;
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element?.getBoundingClientRect().top || 0;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition + offset;

                window.scrollTo({
                  top: offsetPosition,
                });
              }}
            >
              <Users2 className="max-w-5 h-5" />
              Check our Fields
            </Button>
          )}
          {isRegistered && !isSubmitted && (
            <Link to={'/dashboard'}>
              <Button variant={'secondary'} className="w-full">
                <LayoutDashboard className="max-w-5 h-5" />
                See My Dashboard
              </Button>
            </Link>
          )}
          {!isRegistered && !isSubmitted && isLoggedIn && (
            <div className="w-full flex gap-2">
              {!isSubmitted && (
                <Link to="/take-a-test">
                  <Button grid="w-full" variant={'ghost'}>
                    <Image
                      src="./icons/file-text-gradient.svg"
                      alt="File text icon"
                      className="w-5 h-5"
                    />
                    Take a Test
                  </Button>
                </Link>
              )}

              <Button
                variant={'ghost'}
                grid="w-full"
                onClick={() => {
                  const element = document.getElementById('fields');
                  const offset = -100;
                  const bodyRect = document.body.getBoundingClientRect().top;
                  const elementRect = element?.getBoundingClientRect().top || 0;
                  const elementPosition = elementRect - bodyRect;
                  const offsetPosition = elementPosition + offset;

                  window.scrollTo({
                    top: offsetPosition,
                  });
                }}
              >
                <Image
                  src="./icons/users-2-gradient.svg"
                  alt="Users icon"
                  className="w-5 h-5"
                />
                Check our Fields
              </Button>
            </div>
          )}
          {(isRegistered || isSubmitted) && (
            <div className="w-full flex gap-2">
              {!isSubmitted && (
                <Link to="/take-a-test">
                  <Button grid="w-full" variant={'ghost'}>
                    <Image
                      src="./icons/file-text-gradient.svg"
                      alt="File text icon"
                      className="w-5 h-5"
                    />
                    Take a Test
                  </Button>
                </Link>
              )}

              <Button
                variant={'ghost'}
                grid="w-full"
                onClick={() => {
                  const element = document.getElementById('fields');
                  const offset = -100;
                  const bodyRect = document.body.getBoundingClientRect().top;
                  const elementRect = element?.getBoundingClientRect().top || 0;
                  const elementPosition = elementRect - bodyRect;
                  const offsetPosition = elementPosition + offset;

                  window.scrollTo({
                    top: offsetPosition,
                  });
                }}
              >
                <Image
                  src="./icons/users-2-gradient.svg"
                  alt="Users icon"
                  className="w-5 h-5"
                />
                Check our Fields
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
