import Image from '~/components/ui/image';

export default function WhatIsCompfest() {
  return (
    <div className="relative mt-16 md:mt-24 pt-4 md:pt-8">
      <Image
        src="./landing-what-is-compfest-background.png"
        className="absolute top-1/2 -translate-y-1/2 left-0 w-full opacity-75"
        alt="Abstract background design for the COMPFEST section"
      />
      <div className="relative z-10 flex items-center flex-col-reverse md:flex-row md:gap-5 px-20 max-md:px-14 max-sm:px-5">
        <div className="md:w-4/5 flex flex-col gap-2 md:gap-6">
          <h1 className="max-md:text-center font-tilt-warp text-2xl md:text-3xl xl:text-5xl">
            But wait, <br className="md:hidden" /> what is{' '}
            <span className="bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
              COMPFEST
            </span>
            ?
          </h1>
          <p className="text-justify max-md:text-xs max-lg:text-sm leading-relaxed font-medium">
            COMPFEST adalah acara IT terbesar yang diselenggarakan oleh
            mahasiswa Fakultas Ilmu Komputer, Universitas Indonesia. Dengan
            berbagai acara seperti Event, Competition, dan Academy, COMPFEST
            berupaya untuk memberikan edukasi seputar dunia IT kepada masyarakat
            Indonesia. Tahun ini, COMPFEST akan membawa &quot;Awakening Inner
            Brilliance to Adapt to the Shifting Tech Landscape&quot; sebagai
            tema besar.
          </p>
        </div>
        <Image
          src="./vim/vim-confused.svg"
          className="max-md:h-32 md:w-1/5 hidden dark:block"
          alt="Illustration of a confused Vim mascot"
        />
        <Image
          src="./vim/vim-confused-light.svg"
          className="max-md:h-32 md:w-1/5 dark:hidden"
          alt="Illustration of a confused Vim mascot"
        />
      </div>
    </div>
  );
}
