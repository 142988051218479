import { useLoaderData } from '@remix-run/react';
import About from './sections/about';
import CoreValues from './sections/core-values';
import CTA from './sections/cta';
import Hero from './sections/hero';
import OurAchievements from './sections/our-achievements';
import OurFields from './sections/our-fields';
import TakeATest from './sections/take-a-test';
import Testimonial from './sections/testimonial';
import LandingTimeline from './sections/timeline';
import WhatIsCompfest from './sections/what-is-compfest';

export default function LandingModule() {
  const loaderData = useLoaderData<{
    isRegisterClosed: boolean;
    isRegistered: boolean;
    isSubmitted: boolean;
    isLoggedIn: boolean;
  }>();

  return (
    <div className="flex flex-col bg-[#F4F4F4] dark:bg-black">
      <Hero loaderData={loaderData} />
      <About />
      <LandingTimeline />
      <WhatIsCompfest />
      <CoreValues />
      <OurAchievements />
      <OurFields isRegisterClosed={loaderData.isRegisterClosed} />
      <TakeATest />
      <Testimonial />
      <CTA isRegisterClosed={loaderData.isRegisterClosed} />
    </div>
  );
}
