import { Timeline } from '~/components/elements/Timeline';
import { TIMELINE_EVENTS } from '../constants';

export default function LandingTimeline() {
  return (
    <div className="relative mt-32 md:mt-48 xl:mt-56">
      <div className="absolute -top-4 md:-top-6 w-full flex justify-center">
        <h1 className="z-20 text-center w-fit font-tilt-warp text-4xl md:text-5xl bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
          Our Timeline
        </h1>
      </div>
      <div className="bg-component-light-card dark:bg-component-dark-card relative z-10 py-8 sm:py-12 overflow-hidden px-4 sm:px-10 rounded-2xl">
        <Timeline events={TIMELINE_EVENTS} />
      </div>
    </div>
  );
}
