import { LoaderFunctionArgs } from '@remix-run/node';
import { ClientLoaderFunctionArgs } from '@remix-run/react';
import Loading from '~/components/loading';
import LandingModule from '~/modules/LandingModule';
import LandingClientLoader from '~/modules/LandingModule/client-loader';
import { LandingLoader } from '~/modules/LandingModule/loader';

export async function loader(args: LoaderFunctionArgs) {
  return await LandingLoader(args);
}

export async function clientLoader(args: ClientLoaderFunctionArgs) {
  return LandingClientLoader(args);
}

clientLoader.hydrate = true;

export function HydrateFallback() {
  return <Loading />;
}

export default function Index() {
  return <LandingModule />;
}
