import React from 'react';
import { IconProps } from './interface';

export const WhatsApp: React.FC<IconProps> = ({
  fill = 'fill-[#1A2223]',
  className,
  size,
}) => {
  return (
    <svg
      className={`${className} ${size}`}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0488 5.36447C17.1788 3.48447 14.6888 2.45447 12.0388 2.45447C6.57883 2.45447 2.12883 6.90447 2.12883 12.3645C2.12883 14.1145 2.58883 15.8145 3.44883 17.3145L2.04883 22.4545L7.29883 21.0745C8.74883 21.8645 10.3788 22.2845 12.0388 22.2845C17.4988 22.2845 21.9488 17.8345 21.9488 12.3745C21.9488 9.72447 20.9188 7.23447 19.0488 5.36447ZM12.0388 20.6045C10.5588 20.6045 9.10883 20.2045 7.83883 19.4545L7.53883 19.2745L4.41883 20.0945L5.24883 17.0545L5.04883 16.7445C4.22883 15.4345 3.78883 13.9145 3.78883 12.3645C3.78883 7.82447 7.48883 4.12447 12.0288 4.12447C14.2288 4.12447 16.2988 4.98447 17.8488 6.54447C19.4088 8.10447 20.2588 10.1745 20.2588 12.3745C20.2788 16.9145 16.5788 20.6045 12.0388 20.6045ZM16.5588 14.4445C16.3088 14.3245 15.0888 13.7245 14.8688 13.6345C14.6388 13.5545 14.4788 13.5145 14.3088 13.7545C14.1388 14.0045 13.6688 14.5645 13.5288 14.7245C13.3888 14.8945 13.2388 14.9145 12.9888 14.7845C12.7388 14.6645 11.9388 14.3945 10.9988 13.5545C10.2588 12.8945 9.76883 12.0845 9.61883 11.8345C9.47883 11.5845 9.59883 11.4545 9.72883 11.3245C9.83883 11.2145 9.97883 11.0345 10.0988 10.8945C10.2188 10.7545 10.2688 10.6445 10.3488 10.4845C10.4288 10.3145 10.3888 10.1745 10.3288 10.0545C10.2688 9.93447 9.76883 8.71447 9.56883 8.21447C9.36883 7.73447 9.15883 7.79447 9.00883 7.78447C8.85883 7.78447 8.69883 7.78447 8.52883 7.78447C8.35883 7.78447 8.09883 7.84447 7.86883 8.09447C7.64883 8.34447 7.00883 8.94447 7.00883 10.1645C7.00883 11.3845 7.89883 12.5645 8.01883 12.7245C8.13883 12.8945 9.76883 15.3945 12.2488 16.4645C12.8388 16.7245 13.2988 16.8745 13.6588 16.9845C14.2488 17.1745 14.7888 17.1445 15.2188 17.0845C15.6988 17.0145 16.6888 16.4845 16.8888 15.9045C17.0988 15.3245 17.0988 14.8345 17.0288 14.7245C16.9588 14.6145 16.8088 14.5645 16.5588 14.4445Z"
        className={fill}
      />
    </svg>
  );
};
