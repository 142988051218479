import { useNavigate } from '@remix-run/react';
import { useTheme } from 'remix-themes';
import { Button } from '~/components/ui/button';
import Image from '~/components/ui/image';

const CTA = ({ isRegisterClosed }: { isRegisterClosed: boolean }) => {
  const [theme] = useTheme();

  const navigate = useNavigate();

  return (
    <>
      <div className="relative pb-16 pt-72 max-lg:pt-60 max-md:pt-48 max-sm:pt-32 overflow-x-clip mt-12 lg:mt-24 mb-[400px]">
        {/* Background */}
        <div className="absolute animate-pulse top-[-150px] max-md:top-[-30%] max-sm:top-[-30%] left-0 w-[345px] max-lg:w-[320px] max-md:w-[250px] max-sm:w-[203px] aspect-[1/1]">
          <Image src="./bgCta1.svg" alt="cta" className="object-contain" />
        </div>
        <div className="absolute animate-pulse top-[-100px] max-md:top-[-30%] max-sm:top-[-30%] rotate-180 right-[-130px] max-md:right-[-80px] w-[395px] max-lg:w-[350px] max-md:w-[290px] max-sm:w-[250px] aspect-[1/1]">
          <Image src="./bgCta1.svg" alt="cta" className="object-contain" />
        </div>
        <div className="absolute animate-pulse bottom-[-160px] max-lg:bottom-[-100px] max-sm:bottom-[-80px] rotate-180 w-[467px] max-lg:w-[350px] max-md:w-[300px] max-sm:w-[157px] aspect-[1/1]">
          <Image src="./bgCta2.svg" alt="cta" className="object-contain" />
        </div>
        <div className="absolute animate-pulse bottom-[-160px] max-sm:bottom-[-100px] right-[-50px] rotate-[-180deg] w-[475px] max-lg:w-[350px] max-md:w-[290px] max-sm:w-[253px] aspect-[1/1]">
          <Image src="./bgCta1.svg" alt="cta" className="object-contain" />
        </div>

        {/* End Background */}
        <div className="relative w-full h-full">
          <div className="absolute bottom-0 left-[50%] -translate-x-[50%] w-[980px] max-lg:w-[750px] max-md:w-[650px] max-sm:w-[366px] aspect-[980/348]">
            <Image
              src={`./${theme === 'dark' ? 'ellipsDark.svg' : 'ellipsLight.svg'}`}
              alt="cta"
              className="object-contain"
            />
          </div>
          <div className="absolute top-0 translate-y-[-100%] left-[50%] -translate-x-[50%] w-[258px] max-lg:w-[200px] max-md:w-[150px] max-sm:w-[88px] aspect-[1032/1139] hover:rotate-12 duration-300">
            <Image src="./ctaMascot.png" alt="cta" className="object-contain" />
          </div>
          <div className=" flex flex-col items-center gap-8 max-lg:gap-6 max-md:gap-5 max-sm:gap-3 pb-16 max-lg:pb-12 max-md:pb-10 max-sm:pb-3">
            <h1 className="z-[1] text-6xl max-lg:text-5xl max-md:text-4xl max-sm:text-2xl text-center font-tilt-warp bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
              Are you ready to join us?
            </h1>
            <Button
              disabled={isRegisterClosed}
              onClick={() => navigate('/register')}
            >
              <Image
                src="./li_user-circle.svg"
                alt="users"
                className="object-contain w-6 max-sm:w-4"
              />
              Register Now
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTA;
