import React, { CSSProperties } from 'react';
import Marquee from 'react-fast-marquee';
import Image from '~/components/ui/image';

interface FieldItemProps {
  src: string;
  alt: string;
}

interface FieldMarqueeProps {
  style?: CSSProperties;
  direction?: 'left' | 'right' | 'up' | 'down';
  className?: string;
}

const FieldItem: React.FC<FieldItemProps> = ({ src, alt }) => (
  <div className="w-24 h-24 md:w-28 md:h-28 lg:w-36 lg:h-36 max-lg:rounded-xl rounded-2xl p-3 md:p-4 lg:p-5 mx-2 bg-white dark:bg-[#151515] flex justify-center items-center">
    <Image src={src} alt={alt} />
  </div>
);

const FieldMarquee: React.FC<FieldMarqueeProps> = ({
  style,
  direction,
  className,
}) => (
  <Marquee style={style} direction={direction} className={className}>
    <FieldItem src="./brands/fields/academy.webp" alt="Field: Academy" />
    <FieldItem src="./brands/fields/competition.webp" alt="Field: Competition" />
    <FieldItem src="./brands/fields/event.webp" alt="Field: Event" />
    <FieldItem src="./brands/fields/finance.webp" alt="Field: Finance" />
    <FieldItem src="./brands/fields/itdev.webp" alt="Field: IT Development" />
    <FieldItem src="./brands/fields/marketing.webp" alt="Field: Marketing" />
    <FieldItem src="./brands/fields/partnership.webp" alt="Field: Partnership" />
    <FieldItem src="./brands/fields/secretary.webp" alt="Field: Secretary" />
  </Marquee>
);

const AboutDesktop: React.FC = () => (
  <div className="max-md:hidden relative w-full px-5 mt-32 xl:mt-64">
    <div className="absolute pointer-events-none -translate-y-1/2 top-1/2 md:-left-16 lg:-left-16 xl:-left-16 blur-[100px] md:blur-[130px] lg:blur-[160px] xl:blur-[224.69px] bg-cosmic-jade-light-500 dark:bg-cosmic-jade-dark-500 w-[170px] h-[170px] md:w-[210px] md:h-[210px] lg:w-[270px] lg:h-[270px] xl:w-[304px] xl:h-[304px] rounded-[1000px]"></div>
    <div className="absolute pointer-events-none -translate-y-1/2 top-1/2 md:-right-16 lg:-right-16 xl:-right-16 blur-[100px] md:blur-[130px] lg:blur-[160px] xl:blur-[224.69px] bg-solar-amber-light-500 dark:bg-solar-amber-dark-500 w-[170px] h-[170px] md:w-[210px] md:h-[210px] lg:w-[270px] lg:h-[270px] xl:w-[304px] xl:h-[304px] rounded-[1000px]"></div>
    <div className="relative z-10 w-full max-w-screen-xl mx-auto">
      <Image
        src="./landing-about-dark.svg"
        className="hidden dark:inline"
        alt="Ilustrasi bagian About untuk mode gelap"
      />
      <Image
        src="./landing-about-light.svg"
        className="inline dark:hidden"
        alt="Ilustrasi bagian About untuk mode terang"
      />
      <div className="w-full absolute top-1/2 -translate-y-1/2 left-0 p-16">
        <div className="relative flex justify-end items-center gap-8 xl:gap-16">
          <FieldMarquee
            style={{ width: '50%', position: 'absolute', left: '-12%' }}
            direction="up"
            className="max-lg:p-5 p-6 bg-[#E9E9E9] dark:bg-neutral-900 border border-[#D9D9D9] dark:border-none max-lg:rounded-[20px] rounded-[36px]"
          />
          <div className="w-[70%] z-10 max-w-3xl flex flex-col gap-5">
            <h1 className="w-fit font-tilt-warp max-lg:text-3xl text-4xl bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
              Open Recruitment
            </h1>
            <p className="max-lg:text-xs text-justify">
              Mau jadi bagian dari tim di balik salah satu event IT terbesar di
              Indonesia? Saatnya gabung di Open Recruitment COMPFEST! 🚀 Ini
              kesempatan buat kamu nunjukin potensi, ngasah skill, dan
              berkontribusi dalam menciptakan inovasi di dunia teknologi. Yuk,
              gabung dan jadi bagian dari perjalanan seru ini! 🔥
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const AboutMobile: React.FC = () => (
  <div className="relative md:hidden px-5">
    <div className="absolute pointer-events-none -translate-y-1/2 top-1/2 -left-16 blur-[100px] md:blur-[130px] lg:blur-[160px] xl:blur-[224.69px] bg-cosmic-jade-light-500 dark:bg-cosmic-jade-dark-500 w-[170px] h-[170px] md:w-[210px] md:h-[210px] lg:w-[270px] lg:h-[270px] xl:w-[304px] xl:h-[304px] rounded-[1000px]"></div>
    <div className="absolute pointer-events-none -translate-y-1/2 top-1/2 -right-16 blur-[100px] md:blur-[130px] lg:blur-[160px] xl:blur-[224.69px] bg-solar-amber-light-500 dark:bg-solar-amber-dark-500 w-[170px] h-[170px] md:w-[210px] md:h-[210px] lg:w-[270px] lg:h-[270px] xl:w-[304px] xl:h-[304px] rounded-[1000px]"></div>
    <FieldMarquee
      style={{ transform: 'translate', translate: '0 80px', width: '90%' }}
      direction="left"
      className="z-20 p-3 mx-auto bg-[#E9E9E9] dark:bg-neutral-900 border border-[#D9D9D9] dark:border-none rounded-2xl"
    />
    <div className="bg-white dark:bg-[#151515] rounded-3xl px-4 pt-24 pb-4 z-10 relative flex flex-col items-center">
      <h1 className="text-center w-fit font-tilt-warp text-2xl bg-gradient-to-r from-cosmic-jade-light-500 to-solar-amber-light-500 dark:from-cosmic-jade-dark-500 dark:to-solar-amber-dark-500 inline-block text-transparent bg-clip-text">
        Open Recruitment
      </h1>
      <p className="mt-3 tracking-wide max-lg:text-xs text-justify">
        Mau jadi bagian dari tim di balik salah satu event IT terbesar di
        Indonesia? Saatnya gabung di Open Recruitment COMPFEST! 🚀 Ini
        kesempatan buat kamu nunjukin potensi, ngasah skill, dan berkontribusi
        dalam menciptakan inovasi di dunia teknologi. Yuk, gabung dan jadi
        bagian dari perjalanan seru ini! 🔥
      </p>
    </div>
  </div>
);

const About: React.FC = () => {
  return (
    <>
      <AboutDesktop />
      <AboutMobile />
    </>
  );
};

export default About;
