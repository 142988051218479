const Card = (props: { title: string; description: string[] }) => {
  const { title, description } = props;
  return (
    <div className="w-full h-full max-lg:max-h-80 flex flex-col gap-6 bg-neutral-050 dark:bg-[#151515] rounded-[28px] border border-neutral-100 dark:border-neutral-900 px-10 py-9">
      <h1 className="font-tilt-warp text-3xl max-lg:text-2xl max-md:text-xl max-sm:text-lg">
        {title}
      </h1>
      <p
        className="overflow-y-scroll"
        style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
      >
        {description.map((item, index) => (
          <span key={index} className="block text-base font-ubuntu font-normal">
            {item}
          </span>
        ))}
      </p>
    </div>
  );
};
export default Card;
